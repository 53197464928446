import {
    BEARER_TOKEN_KEY
} from './user';

export const getCookie = (key: string) => {
    return localStorage.getItem(key);
    // const cookies = document.cookie.split(';').filter(c => {
    //     return c.startsWith(`${key}=`);
    // });
    // if (cookies.length) {
    //     return cookies[0].replace(
    //         `${key}=`,
    //         ''
    //     );
    // }
    // return null;
};

export const setCookie = (
    key: string, value: string
) => {
    return localStorage.setItem(
        key,
        value
    );
    // let expires = '';
    // if (days) {
    //     const date = new Date();
    //     date.setTime(date.getTime() + (days*24*60*60*1000));
    //     expires = `; expires=${date.toUTCString()}`;
    // }
    // document.cookie = `${key}=${value || ''}${expires}; path=/`;
};

export const eraseCookie = () => {
    localStorage.clear();
    document.cookie = `${BEARER_TOKEN_KEY}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `${BEARER_TOKEN_KEY}=; Path=/user/login-register; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
