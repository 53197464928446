import {
    setCookie
} from '../helpers/cookie';
import {
    BEARER_TOKEN_KEY
} from '../helpers/user';
import {
    apiGetAuthenticated, apiPostAuthenticated,
    apiPostUnauthenticated
} from './api';

export const fetchUser = async() => {
    return await apiGetAuthenticated('/user');
};

export const login = async (
    email, password
) => {
    const result = await apiPostUnauthenticated(
        '/user/login',
        {
            email,
            password,
            device_name: 'browser',
        }
    );
    if (result.success) {
        document.cookie = `${BEARER_TOKEN_KEY}=${result.token}`;
        setCookie(
            BEARER_TOKEN_KEY,
            result.token
        );
    }

    return {
        success: !!result.success,
        errors: result.errors,
    };
};

export const registerUser = async (
    name, email, password, how_did_you_hear_about_us
) => {
    const result = await apiPostUnauthenticated(
        '/user/register',
        {
            name,
            email,
            password,
            how_did_you_hear_about_us,
            device_name: 'browser',
        }
    );
    if (result.success) {
        setCookie(
            BEARER_TOKEN_KEY,
            result.token
        );
    }

    return !!result.success;
};

export const deleteAuthTokens = async() => {
    await apiPostAuthenticated('/user/logout');
};

export const sendPasswordResetMail = (email: string) => {
    apiPostUnauthenticated(
        '/user/password/reset',
        {
            email,
        }
    );
};

export const storeNewPassword = async (
    email: string,
    password: string,
    password_confirmation: string,
    token: string
) => {
    return await apiPostUnauthenticated(
        '/user/password/update',
        {
            email,
            password,
            password_confirmation,
            token,
        }
    );
};
