import * as React from 'react';
import {
    getBreakpointLabel
} from '../../helpers/breakpoints';
// @ts-ignore
import * as styles from './CSSGrid.module.css';
import {
    useEffect,
    useState
} from 'react';

const CSSGrid = () => {
    const display = 'undefined' !== typeof window && '#show-grid' === window.location.hash;

    if (!display) {
        return null;
    }

    const [breakpoint, setBreakpoint,] = useState(getBreakpointLabel());
    useEffect(
        () => {
            const resizeListener = () => {
                setBreakpoint(getBreakpointLabel());
            };
            window.addEventListener(
                'resize',
                resizeListener
            );
            setBreakpoint(getBreakpointLabel());

            return () => {
                window.removeEventListener(
                    'resize',
                    resizeListener
                );
            };
        },
        []
    );

    if ('mobile' === breakpoint) {
        return <div className={styles.container}>
            <div className={styles.column}/>
            <div className={styles.column}/>
        </div>;

    }

    if ('tablet' === breakpoint) {
        return <div className={styles.container}>
            <div className={styles.column}/>
            <div className={styles.column}/>
            <div className={styles.column}/>
            <div className={styles.column}/>
            <div className={styles.column}/>
            <div className={styles.column}/>
            <div className={styles.column}/>
            <div className={styles.column}/>
        </div>;
    }

    return <div className={styles.container}>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
        <div className={styles.column}/>
    </div>;
};

export default CSSGrid;
