export const isBrowser = () => {
    return 'undefined' !== typeof window;
};

export const isIE = () => {
    if (!isBrowser()) {
        return false;
    }
    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11

    return (0 < msie || 0 < trident);
};
