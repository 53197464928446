export const isMobile = () => {
    return 600 > window?.innerWidth;
};

export const isTablet = () => {
    return 600 < window?.innerWidth && 1024 > window.innerWidth;
};

export const isDesktop = () => {
    return 1024 <= window?.innerWidth;
};

export const getBreakpointLabel = () => {
    if (isMobile()) {
        return 'mobile';
    }

    if (isTablet()) {
        return 'tablet';
    }

    return 'desktop';
};
