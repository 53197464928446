import {
    isBrowser
} from './browser';
import {
    eraseCookie
} from './cookie';
import {
    deleteAuthTokens
} from '../api/user';
import {
    navigate
} from 'gatsby';
export const BEARER_TOKEN_KEY = 'bearer_token';

export const logout = async () => {
    await deleteAuthTokens();
    setAuthUser(null);
    eraseCookie();
    setTimeout(
        () => {
            navigate('/user/logged-out');
        },
        250
    );
};

export const getAuthUser = () => {
    return isBrowser() && window.localStorage.getItem('gatsbyUser')
        ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
        : null;
};

export const setAuthUser = user => {
    return window.localStorage.setItem(
        'gatsbyUser',
        JSON.stringify(user)
    );
};

export const authUserIsLoggedIn = () => {
    const user = getAuthUser();
    return !!user;
};
