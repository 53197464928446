import {
    eraseCookie, getCookie
} from '../helpers/cookie';
import {
    BEARER_TOKEN_KEY
} from '../helpers/user';
import {
    navigate
} from 'gatsby';

const getApiDomain = () => {
    if ('undefined' !== typeof window && 'localhost' === window.location.hostname) {
        return 'http://localhost:9004';
    }
    if ('undefined' !== typeof window && 'gatsby' === window.location.hostname) {
        return 'http://laravel:8000';
    }
    if ('undefined' !== typeof window && 'focusinnovationspreispreview.gatsbyjs.io' === window.location.hostname) {
        return 'https://focus-innovationspreis.one-pixel-ahead.com';
    }
    return 'https://api.focus-innovationspreis.de';
};

// @ts-ignore
export const apiDomain = getApiDomain();

/**
 * Make an api GET call as unauthenticated user
 *
 * @param path
 * @returns {Promise<any>}
 */
export const apiGetUnauthenticated = async (path) => {
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
    );
    return await response.json();
};

/**
 * Make an api POST call as unauthenticated user
 *
 * @param path
 * @param body
 * @returns {Promise<any>}
 */
export const apiPostUnauthenticated = async (
    path, body
) => {
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
    );
    return await response.json();
};

/**
 * Make an api GET call as authenticated user
 *
 * @param path
 * @returns {Promise<any>}
 */
export const apiGetAuthenticated = async (path) => {
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie(BEARER_TOKEN_KEY)}`,
            },
        }
    );
    const result = await response.json();
    if (401 === response.status) { // user is not authenticated
        eraseCookie();
        await navigate('/user/login-register');
    }
    return result;
};

/**
 * Make an api POST call as authenticated user
 *
 * @param path
 * @param body
 * @returns {Promise<any>}
 */
export const apiPostAuthenticated = async (
    path, body = {}
) => {
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie(BEARER_TOKEN_KEY)}`,
            },
            body: JSON.stringify(body),
        }
    );

    const result = await response.json();
    if (401 === response.status) { // user is not authenticated
        eraseCookie();
        await navigate('/user/login-register');
    }
    return result;
};

/**
 * Make an api POST call as authenticated user
 *
 * @param path
 * @param fieldName
 * @param file
 * @returns {Promise<any>}
 */
export const apiUploadFileAuthenticated = async (
    path: string, fieldName: string, file: File
) => {
    const formData = new FormData();
    formData.append(
        'file',
        file
    );
    formData.append(
        'name',
        fieldName
    );
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            method: 'POST',
            headers: {
                // Accept: 'application/json',
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie(BEARER_TOKEN_KEY)}`,
            },
            body: formData,
        }
    );
    if (401 === response.status) { // user is not authenticated
        eraseCookie();
        await navigate('/user/login-register');
    }
    return await response.json();
};


/**
 * Make an api GET call to load an image into an <img> tag as authenticated user
 */
export const apiLoadImageAuthenticated = async (
    path,
    imgTag
) => {
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie(BEARER_TOKEN_KEY)}`,
            },
        }
    );
    if (401 === response.status) { // user is not authenticated
        eraseCookie();
        await navigate('/jury/login');
    }
    const blob = await response.blob();
    imgTag.src = URL.createObjectURL(blob);
};

export const apiDisplayPdfAuthenticated = async (
    path
) => {
    const response = await fetch(
        `${apiDomain}/api${path}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie(BEARER_TOKEN_KEY)}`,
            },
        }
    );
    if (401 === response.status) { // user is not authenticated
        eraseCookie();
        await navigate('/jury/login');
    }
    const blob = await response.blob();
    console.log(blob);
};
